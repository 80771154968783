import { configNumberFormatter, formatters } from "@/helpers/formatters";
import { trendColors } from "@/settings/summary/summary";
import { InputDictionary } from "@/types/common";

export default <InputDictionary[]>[
  {
    title: "Acquisitions",
    value: "acquisitions",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "App Events",
    value: "app_events",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "App Installs",
    value: "app_installs",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Bookings",
    value: "bookings",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Clicks",
    value: "clicks",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Completed Views",
    value: "completed_views",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "CPA",
    value: "cpa",
    formatter: formatters.currencyFormat,
    // TODO: Probably we should set `configFormatter: configNumberFormatter.currencyFormat` for all currency metric.
    // TODO: Or pass something like standard settings.
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPAE",
    value: "cpae",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPB",
    value: "cpb",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPC",
    value: "cpc",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPCV",
    value: "cpcv",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPE",
    value: "cpe",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPER",
    value: "cper",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPI",
    value: "cpi",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPL",
    value: "cpl",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPIA",
    value: "cpia",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPLA",
    value: "cpla",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPLC",
    value: "cplc",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPLV",
    value: "cplv",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPM",
    value: "cpm",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPP",
    value: "cpp",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPPL",
    value: "cppl",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPS",
    value: "cps",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPSA",
    value: "cpsa",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CPSV",
    value: "cpsv",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.negative,
    table: { group: "calculated" },
  },
  {
    title: "CTR",
    value: "ctr",
    formatter: formatters.percentFormat,
    // TODO: Probably we should pass something like standard settings for `configFormatter` for all the percent metrics.
    configFormatter: configNumberFormatter.percentFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "calculated" },
  },
  {
    title: "Engagements",
    value: "engagements",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Event Response",
    value: "event_response",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Global Add-to-Cart",
    value: "global_add_to_carts",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Global Bookings",
    value: "global_bookings",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Global New Users",
    value: "global_new_users",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Global Purchases",
    value: "global_purchases",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Global Revenue",
    value: "global_revenue",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Global Sessions",
    value: "global_sessions",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Impressions",
    value: "impressions",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard", order: 20 },
  },
  {
    title: "Intent Actions",
    value: "intent_actions",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Landing Page Visits",
    value: "landing_page_visits",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Leads",
    value: "leads",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Lifetime Reach",
    value: "lifetime_reach",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: false,
    table: { group: "standard" },
  },
  {
    title: "Lifetime Frequency",
    value: "lifetime_frequency",
    formatter: formatters.numberFixedFormat,
    configFormatter: configNumberFormatter.numberFixedFormat,
    showTrend: false,
    table: { group: "calculated" },
  },
  {
    title: "Link Clicks",
    value: "link_clicks",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Local Action",
    value: "local_action",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  // TODO: This metric is not confirmed yet.
  // {
  //   title: "Meta Engagement",
  //   value: "meta_enagement",
  //   formatter: formatters.integerFormat,
  //   configFormatter: configNumberFormatter.integerFormat,
  //   showTrend: true,
  //   trendUpColor: trendColors.positive,
  //   table: { group: "standard" },
  // },
  {
    title: "Page Likes",
    value: "page_likes",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Purchase Value",
    value: "purchase_value",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Purchases",
    value: "purchases",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "ROAS",
    value: "roas",
    formatter: formatters.numberFixedFormat,
    configFormatter: configNumberFormatter.numberFixedFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "calculated" },
  },
  {
    title: "Site Action",
    value: "site_action",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Spend",
    value: "spend",
    formatter: formatters.currencyFormat,
    configFormatter: configNumberFormatter.integerCurrencyFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard", order: 10 },
  },
  {
    title: "Store Visits",
    value: "store_visits",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "Submissions/Entries",
    value: "submission_entries",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "VCR",
    value: "vcr",
    formatter: formatters.percentFormat,
    configFormatter: configNumberFormatter.percentFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "calculated" },
  },
  {
    title: "Video Views",
    value: "video_views",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    showTrend: true,
    trendUpColor: trendColors.positive,
    table: { group: "standard" },
  },
  {
    title: "# Of Advertisers",
    value: "advertisers",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Agencies",
    value: "agencies",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Campaign KPIs",
    value: "campaign_kpis",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Campaigns",
    value: "campaigns",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Channel Details",
    value: "channel_details",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Channel Objectives",
    value: "channel_objectives",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Channels",
    value: "channels",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of DSPs",
    value: "dsps",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Industries",
    value: "industries",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Industry Targets",
    value: "industry_targets",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Planning Stages",
    value: "planning_stages",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
  {
    title: "# Of Sub-industries",
    value: "sub_industries",
    formatter: formatters.integerFormat,
    configFormatter: configNumberFormatter.integerFormat,
    isCountableMetric: true,
  },
];
